<script>
// Extensions
import View from "@/views/View";

// Mixins
import LoadSections from "@/mixins/load-sections";

export default {
  name: "electrodomesticos",

  metaInfo: { title: "electrodomesticos" },

  extends: View,

  mixins: [
    LoadSections([
      "hero-alt",
      "electrodomesticoscontent"
      // 'theme-features',
      // 'affiliates',
      // 'newsletter',
      // 'pro-features',
      // 'info',
    ])
  ],

  props: {
    id: {
      type: String,
      default: "electrodomesticos"
    }
  }
};
</script>
